import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './ContactForm.scss';

class ContactForm extends Component {
  componentDidMount() {
    this.initHubSpotForm();
  }

  initHubSpotForm() {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '47606434',
          formId: this.props.formID,
          target: "#hubspotForm",
          css: '',
          submitText: this.props.submitText
        })
      }
    });
  }

  render() {

    return (
        <div className={`contact-form-container ${this.props.onContactPage ? 'contact-form--contact-page' : ''}`}>
          <img src={this.props.formDecoration} alt="" className="contact-form-decoration"/>
          <div className={'contact-form'}>
            <div id="hubspotForm" className="hubspot-form"></div>
          </div>
        </div>
    );
  }
}

ContactForm.propTypes = {
  formID: PropTypes.string.isRequired, // The ID of the HubSpot Form
  submitText: PropTypes.string.isRequired, // The Submit text of the HubSpot Form
  onContactPage: PropTypes.bool,
  pricingPlan: PropTypes.string
};

export default ContactForm;